import React from 'react';

import Header from '../../components/header';
import Newsletter from '../../components/newsletter';
import Footer from '../../components/footer';

import RegisterHeader from './header';
import RegisterFields from './fields';

export default function ResetPasswordFirsStep() {
  return (
    <>
      <Header />
        <RegisterHeader />
        <RegisterFields />
      <Newsletter />
      <Footer />
    </>
  )
}